import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import APCategoryBtns from '../../components/preowned/APCategoryBtns'

// markup
const APPerpetualCalendar = () => {
  const data = useStaticQuery(
    graphql`
      query queryAPPerpetualCalendar {
        products: allStrapiProduct(
          filter: { brand: { eq: "Audemars Piguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Audemars Piguet Perpetual Calendar Watches'}
      canonical={'/fine-watches/audemars-piguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Audemars Piguet Perpetual Calendar watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/audemars-piguet/audemars-piguet-royal-oak/">
              <StaticImage
                src="../../images/preowned/Pre-Owned-Audemars-Piguet-Perpetual-Calendar--1920x520.jpg"
                alt="Pre-Owned Certified Used Audemars Piguet Perpetual Watches Header"
                aria-label="pre-owned Audemars Piguet Perpetual Watches Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">About Pre-Owned Used Audemars Piguet Perpetual Calendar</h1>
            <p>
              <a href="/fine-watches/audemars-piguet/filter/model/perpetual-calendar/">
                Audemars Piguet Perpetual Calendar
              </a>{' '}
              watches, also known as{' '}
              <a href="/fine-watches/audemars-piguet/filter/model/quantieme/">
                Audemars Piguet Quantieme Perpetual
              </a>{' '}
              watches, illustrate the renowned Swiss watchmaker’s mastery of mechanical movements.
              The brand has had a long history of making perpetual calendar complications, which
              explains why pre-owned Audemars Piguet Perpetual Calendar watches are some of the most
              sought-after fine timepieces in the secondary market.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Audemars Piguet Perpetual Calendar</b>
              <br />
              <br />
              While Audemars Piguet created its first-ever perpetual calendar wristwatch in 1948,
              it’s the vintage Audemars Piguet Perpetual Calendar ref. 5548 introduced in 1978 that
              is credited for saving the company during the quartz crisis. This Quantième Perpetuel
              by Audemars Piguet was the world’s thinnest automatic perpetual calendar and paved the
              way for other models to follow.
              <br />
              <br />
              Popular Vintage Used Audemars Piguet Perpetual Calendar References:
              <br />
              <br />
              <ul>
                <li>
                  <a href="/">
                    Pre-owned Audemars Piguet Perpetual Calendar Ultra-Thin ref. 5548 later known as
                    ref. 25548 (1978 – 1991)
                  </a>
                </li>
                <li>
                  <a href="/">
                    Pre-owned Audemars Piguet Perpetual Calendar Ultra-Thin ref. 25657 (1982 – 1993)
                  </a>
                </li>
                <li>
                  <a href="/">
                    Pre-owned Audemars Piguet Perpetual Calendar Ultra-Thin ref. 25661 (1985 – 1994)
                  </a>
                </li>
                <li>
                  <a href="/">
                    Pre-owned Audemars Piguet Perpetual Calendar Ultra-Thin ref. 25668 (1988 – 1993)
                  </a>
                </li>
              </ul>
              <br />
              Pre-owned Audemars Piguet Perpetual Calendar complications are also available across
              many Audemars Piguet collections, including Royal Oak, Royal Oak Offshore, Millenary
              and Jules Audemars.
              <br />
              <br />
              <i>Popular Pre-Owned Used Audemars Piguet Perpetual Calendar Models</i>
              <br />
              <br />
              <ul>
                <li>
                  <a href="/fine-watches/audemars-piguet/filter/model/perpetual-calendar/">
                    Pre-owned Audemars Piguet Royal Oak Perpetual Calendar
                  </a>
                </li>
                <li>
                  <a href="/fine-watches/audemars-piguet/filter/model/royal-oak-offshore/">
                    Pre-owned Audemars Piguet Royal Oak Offshore Perpetual Calendar
                  </a>
                </li>
                <li>
                  <a href="/fine-watches/audemars-piguet/filter/model/millenary/">
                    Pre-owned Audemars Piguet Millenary Perpetual Calendar
                  </a>
                </li>
              </ul>
              <br />
              <p>
                <a href="/">Grays & Sons</a> are experts in buying and selling secondhand Audemars
                Piguet Perpetual Calendar watches with over 40 years of experience in the business.
                Our team of watch specialists can help you buy the pre-owned Audemars Piguet
                Quantième Perpetuel of your dreams.
                <br />
                <br />
                Every secondhand Audemars Piguet Perpetual Calendar for sale on Gray & Sons’ website
                is in stock and ready to ship. What’s more, as an independent Audemars Piguet
                dealer, Gray & Sons benefits from both price flexibility and inventory control. Go
                ahead and make a deal with one of our decision-makers today if you’d like to
                purchase the best secondhand Audemars Piguet Perpetual Calendar watches.
                <br />
                <br />
                If, alternatively, you want to know, “Where can I sell my used AP Perpetual Calendar
                watch near me?” Gray & Sons buys high-end timepieces too. Visit{' '}
                <a href="https://sellusyourjewelry.com/"> Sell Us Your Jewelry</a> to start the
                process of selling your used Audemars Piguet Perpetual Calendar watch.
              </p>
              <br />
              <b>
                Gray & Sons is the Best Source for Buying and Selling Pre-Owned Audemars Piguet
                Perpetual Calendar Watches
              </b>
              <br />
              <br />
              Over the last four decades, we have served thousands of happy customers and our
              clients agree that Gray & Sons is the number one seller and buyer of used Audemars
              Piguet Perpetual Calendar watches.
              <br />
              <br />
              If you’re in the Miami area, visit the Gray & Sons boutique, adjacent to the
              prestigious Bal Harbour Shops for the best selection of pre-owned fine timepieces.
              Open six days a week, our watch experts are there to answer any questions as you
              browse our collection of secondhand Audemars Piguet Perpetual Calendar watches for
              sale. Gray & Sons also has an independent Audemars Piguet repair facility for any
              servicing needs.
              <br />
              <br />
              <br />
              <br />
              <a href="/">www.grayandsons.com </a>
              and
              <a href="https://sellusyourjewelry.com/"> www.sellusyourjewelry.com </a>
              <br />
              <b>
                <a href="tel:+13057706955">CALL: (305) 770-6955</a>
              </b>{' '}
              <br />
              <b>
                <a href="sms:786-266-4763">TEXT: Vika</a>
              </b>{' '}
              for a quick response. <br />
              EMAIL:{' '}
              <a href="mailto:sell@grayandsons.com?subject=feedback&body=message">
                sell@grayandsons.com
              </a>{' '}
              <br /> <br />
              Gray and Sons Jewelers specializes in Pre-Owned, Estate,
              <br />
              Vintage, Antique, Heirloom, Used Watches &anp; Jewelry
              <br />
              <br />
              We Buy, Sell Trade, Consign and Repair Used and Pre-Owned Watches and Jewelry
              <br />
              <br />
              Our customers say; "There are no better certified pre-owned Watch Specialists &amp;
              Jewelers than Gray and Sons Jewelers in all of Florida and the US"
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/audemars-piguet/">
                <button>SHOP AUDEMARS PIGUET PERPETUAL CALENDAR WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK AUDEMARS PIGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <APCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default APPerpetualCalendar
